var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    staticClass: "overlay mt-2",
    attrs: {
      "show": _vm.loading,
      "opacity": 0.55,
      "spinner-type": "grow"
    }
  }, [_c('div', {
    staticClass: "page-container d-flex flex-column"
  }, [_c('div', {
    staticClass: "d-flex flex-grow-1 justify-content-between wrapper"
  }, [_c('div', {
    staticClass: "d-flex flex-column mb-3 flex-grow-1 w-100"
  }, [_c('div', {
    staticClass: "d-flex w-100"
  }, [_c('b-form-input', {
    staticClass: "title-input font-weight-bold",
    attrs: {
      "id": "title-input-".concat(_vm.item.id),
      "type": "text",
      "placeholder": _vm.$t('most-important.form.title'),
      "state": _vm.getStateWithoutSubmit('titleValue')
    },
    model: {
      value: _vm.$v.formData.titleValue.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.formData.titleValue, "$model", $$v);
      },
      expression: "$v.formData.titleValue.$model"
    }
  }), !_vm.$v.formData.titleValue.required ? _c('b-form-invalid-feedback', {
    attrs: {
      "id": "title-input-".concat(_vm.item.id, "-feedback")
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('validation.required', {
    field: _vm.$t('most-important.form.title')
  })) + " ")]) : _vm._e(), !_vm.$v.formData.titleValue.maxLength ? _c('b-form-invalid-feedback', {
    attrs: {
      "id": "title-input-".concat(_vm.item.id, "-feedback")
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('validation.max-length', {
    field: _vm.$t('most-important.form.title'),
    max: _vm.$v.formData.titleValue.$params.maxLength.max
  })) + " ")]) : _vm._e()], 1), _c('div', {
    staticClass: "d-flex flex-column flex-grow-1"
  }, [_c('b-form-textarea', {
    staticClass: "text-input h-100",
    attrs: {
      "id": "text-area-".concat(_vm.item.id),
      "placeholder": _vm.$t('most-important.form.text'),
      "no-resize": "",
      "state": _vm.getStateWithoutSubmit('textValue'),
      "wrap": "hard"
    },
    model: {
      value: _vm.$v.formData.textValue.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.formData.textValue, "$model", $$v);
      },
      expression: "$v.formData.textValue.$model"
    }
  }), !_vm.$v.formData.textValue.required ? _c('b-form-invalid-feedback', {
    attrs: {
      "id": "text-area-".concat(_vm.item.id, "-feedback")
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('validation.required', {
    field: _vm.$t('most-important.form.text')
  })) + " ")]) : _vm._e()], 1)]), _c('div', {
    staticClass: "d-flex"
  }, [_c('b-button', {
    staticClass: "button mt-2",
    attrs: {
      "type": "submit",
      "variant": "outline-light"
    },
    on: {
      "click": function click($event) {
        _vm.isCreate ? _vm.create() : _vm.update();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("most-important.button.".concat(_vm.isCreate ? 'create' : 'update'))) + " ")]), !_vm.isCreate ? _c('b-button', {
    staticClass: "button mt-2 ml-4 d-flex align-items-center",
    attrs: {
      "type": "submit",
      "variant": "outline-light"
    },
    on: {
      "click": function click($event) {
        return _vm.onDelete();
      }
    }
  }, [_c('TrashIcon', {
    staticClass: "mr-2"
  }), _c('span', [_vm._v(_vm._s(_vm.$t('most-important.button.delete')))])], 1) : _vm._e()], 1)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }