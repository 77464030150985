

































































































import FormValidationMixin from '@/mixins/FormValidationMixin';
import { MostImportantItem } from '@/model/MostImportantItem';
import { PropType } from 'vue';
import { mixins } from 'vue-class-component';
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { Validations } from 'vuelidate-property-decorators';
import { maxLength, required } from 'vuelidate/lib/validators';
import TrashIcon from '@/assets/icon/trash.svg?inline';

@Component({
    components: {
        TrashIcon,
    },
})
export default class MostImportantItems extends mixins(FormValidationMixin) {
    @Prop({
        type: Object as PropType<MostImportantItem>,
        default: () => {
            return {
                title: '',
                text: '',
            };
        },
    })
    item!: MostImportantItem;

    @Prop({ type: Boolean, default: false }) isCreate!: boolean;
    @Prop({ type: Boolean, default: false }) loading!: boolean;

    formData = {
        titleValue: this.item.title,
        textValue: this.item.text,
    };

    touched = false;

    @Validations()
    validations = {
        formData: {
            titleValue: { required, maxLength: maxLength(255) },
            textValue: { required },
        },
    };

    @Watch('item')
    updateAsset(): void {
        this.formData = {
            titleValue: this.item.title,
            textValue: this.item.text,
        };
    }

    create(): void {
        this.$v.$touch();
        this.touched = true;
        this.onCreate();
    }

    @Emit()
    onCreate(): MostImportantItem {
        return {
            ...this.item,
            title: this.formData.titleValue,
            text: this.formData.textValue,
        };
    }

    update(): void {
        this.$v.formData.$touch();
        this.touched = true;
        this.onUpdate();
    }

    @Emit()
    onUpdate(): MostImportantItem {
        return {
            ...this.item,
            title: this.formData.titleValue,
            text: this.formData.textValue,
        };
    }

    @Emit()
    onDelete(): MostImportantItem {
        return this.item;
    }
}
