




import { Component, Vue } from 'vue-property-decorator';
import MostImportant from '@/active-components/most-important/Items.vue';

@Component({
    components: {
        MostImportant,
    },
})
export default class MostImportantView extends Vue {}
