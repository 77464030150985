

























































import ToastMixin from '@/mixins/ToastMixin';
import MostImportant from '@/components/most-important/Item.vue';
import { MostImportantItem } from '@/model/MostImportantItem';
import ToastVariant from '@/model/toast/toast-variant';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import CustomModal from '@/components/modal/CustomModal.vue';
import ConfirmationModal from '@/components/modal/ConfirmationModal.vue';

@Component({
    components: {
        CustomModal,
        ConfirmationModal,
        MostImportant,
    },
    computed: {
        ...mapGetters({
            items: 'mostImportantStorage/getItems',
        }),
    },
    methods: {
        ...mapActions({
            fetch: 'mostImportantStorage/fetch',
            update: 'mostImportantStorage/update',
            create: 'mostImportantStorage/create',
            delete: 'mostImportantStorage/delete',
        }),
        ...mapMutations({
            setItems: 'mostImportantStorage/setItems',
        }),
    },
})
export default class HowToVideos extends mixins(ToastMixin) {
    fetch!: () => Promise<void>;
    update!: (request: MostImportantItem) => Promise<void>;
    create!: (request: MostImportantItem) => Promise<void>;
    delete!: (request: MostImportantItem | null) => Promise<void>;
    setItems!: (items: MostImportantItem[] | null) => void;

    items!: MostImportantItem[] | null;

    deleteItem: MostImportantItem | null = null;
    loadingItem: null | number = null;
    loading = false;

    get itemList(): MostImportantItem[] | null {
        return this.items;
    }

    set itemList(value: MostImportantItem[] | null) {
        this.setItems(value);
    }

    mounted(): void {
        this.loading = true;
        this.fetch().then(() => {
            this.loading = false;
        });
    }

    onCreate(item: MostImportantItem): void {
        this.loading = true;
        this.create(item)
            .then(() => {
                this.$bvModal.hide(`create-modal`);
                this.showToast(
                    this.$t('most-important.create-success').toString(),
                    this.$t('success-title').toString(),
                    ToastVariant.SUCCESS,
                );
                this.fetch();
            })
            .catch(() => {
                this.showToast(
                    this.$t('most-important.create-failure').toString(),
                    this.$t('error-title').toString(),
                    ToastVariant.DANGER,
                );
            })
            .finally(() => {
                this.loading = false;
            });
    }

    onUpdate(item: MostImportantItem): void {
        this.loadingItem = item.id ?? null;
        this.update(item)
            .then(() => {
                this.$bvModal.hide(`create-modal`);
                this.showToast(
                    this.$t('most-important.update-success').toString(),
                    this.$t('success-title').toString(),
                    ToastVariant.SUCCESS,
                );
                this.fetch();
            })
            .catch(() => {
                this.showToast(
                    this.$t('most-important.update-failure').toString(),
                    this.$t('error-title').toString(),
                    ToastVariant.DANGER,
                );
            })
            .finally(() => {
                this.loadingItem = null;
            });
    }

    onDelete(item: MostImportantItem): void {
        this.$bvModal.show(`delete-modal`);
        this.deleteItem = item;
    }

    onDeleteConfirmed(): void {
        this.loading = true;
        this.delete(this.deleteItem)
            .then(() => {
                this.deleteItem = null;
                this.$bvModal.hide(`delete-modal`);
                this.showToast(
                    this.$t('most-important.delete-success').toString(),
                    this.$t('success-title').toString(),
                    ToastVariant.SUCCESS,
                );
                this.fetch();
            })
            .finally(() => {
                this.loading = false;
            });
    }
}
